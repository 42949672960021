import Keycloak from 'keycloak-js';

const CORE_DEPENDENCY = [
  '@viax/core-import-map',
  '@viax/admin',
  '@viax/core-root-config',
  '@viax/apollo-client',
  '@viax/page-not-found'
];

const CORE_DEPENDENCY_IMPORT_MAP = {
  "@viax/admin": "https://mfa.viax.io/viax/admin/1.116.1/js/app.707b483f.js",
  "@viax/core-root-config": "https://mfa.viax.io/viax/core-root-config/1.15.1/viax-core-root-config.js",
  "@viax/apollo-client": "https://mfa.viax.io/viax/apollo-client/1.8.1/viax-apollo-client.js",
  "@viax/page-not-found": "https://mfa.viax.io/viax/page-not-found/1.0.1/js/app.ffad3f3d.js"
}

declare global {
  interface Window {
    keycloak: any;
    viaxInitConfiguration: (keycloakConfig:KeycloakConfig, apiUrl:string, callBack:()=>void) => void;
    importMapOverrides: any;
    possibleTypes: any;
  }
}

interface ImportResponse  {
  data : {
    getMfaImport: {
      import: string;
    }
  }
}

interface KeycloakConfig  {
  url: string;
  realm: string;
  clientId: string;
}

function getImport(token: string, apiUrl: string, isLocal:boolean, buildNumber:string) : Promise<ImportResponse> {
  return new Promise(resolve => {
    const options = {
      method: 'post',
      headers: {
        "Content-Type": 'application/json',
        'authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        query: `
          query getMfaImport {
            getMfaImport {
              import
            }
          } 
        `
      })
    };

    fetch(apiUrl, options)
      .then(res => res.json())
      .then((data) => {
        resolve(data);
        if(isLocal) {
          const { data: { getMfaImport }} = data;
          localStorage.setItem(
            `localDbsImportMap-${buildNumber}`, getMfaImport.import,
          );
        }
      });
  });
}

function getLocalImportMap(localBuildNumber: string): string | null {
  return localStorage.getItem(
    `localDbsImportMap-${localBuildNumber}`
  );
}

const addScriptTag = (importMap: any, addAfterEl:HTMLScriptElement, src?:  string) => {
  const scriptTag = document.createElement('script');
  scriptTag.type = 'systemjs-importmap';
  if(src) {
    scriptTag.src = src;
  } else {
    scriptTag.textContent = JSON.stringify(importMap);
  }
  addAfterEl.after(scriptTag);
}

const setDynamicImportMap = (dynamicImportMap:ImportResponse) => {
  const imports = JSON.parse(dynamicImportMap.data.getMfaImport.import);

  const filteredByKey = Object.fromEntries(
    Object.entries(imports).filter(([key]) => !CORE_DEPENDENCY.includes(key)),
  );
  const coreImportMaps = imports['@viax/core-import-map'];

  // add script
  const importMap = {
    imports: {
      ...filteredByKey,
      ...CORE_DEPENDENCY_IMPORT_MAP,
    },
  };

  const defaultImportMapElement = document.getElementById('core-import-map') as HTMLScriptElement;

  addScriptTag(importMap, defaultImportMapElement);

  if(coreImportMaps != defaultImportMapElement.src ) {
    addScriptTag(coreImportMaps, defaultImportMapElement, coreImportMaps);
  }
}

const setDevelopImportMap = () => {
  const devImportMapElement = document.getElementById('develop-import-map') as HTMLScriptElement | null;
  if(devImportMapElement) {
    devImportMapElement.type = 'systemjs-importmap';
  }
}

const setImportMapOverrides = () => {
  const overrideMap = window.importMapOverrides.getOverrideMap();
  const devImportMapElement = document.getElementById('develop-import-map') as HTMLScriptElement | null;

  if(overrideMap && devImportMapElement) {
    addScriptTag(overrideMap,devImportMapElement);
  }
}

const getPossibleTypes = async (token: string, apiUrl: string) => {
  await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      variables: {},
      query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
    }),
  }).then(result => result.json())
    .then(result => {
      const possibleTypes: any = {};

      result.data.__schema.types.forEach((supertype: { possibleTypes: any, name: string }) => {
        if (supertype.possibleTypes) {
          possibleTypes[supertype.name] =
            supertype.possibleTypes.map((subtype: { name: string }) => subtype.name);
        }
      });
      window.possibleTypes = possibleTypes;
    });
};

async function getMfaImport(
  token:string,
  apiUrl:string,
  callBack:()=>void,
  buildNumber: string,
  isLocal: string
) {
  const useLocal = isLocal === 'true';
  let localConfig: string | null = null;
  if(useLocal) {
    localConfig = getLocalImportMap(buildNumber);
  }
  const importOverwrite:ImportResponse = (useLocal && localConfig) ?
    {data:
      {getMfaImport: {import: localConfig}}
    } : await getImport(token, apiUrl, useLocal, buildNumber);

  setDynamicImportMap(importOverwrite);
  setDevelopImportMap();
  setImportMapOverrides();

  callBack();
}

const init = function(keycloakConfig:KeycloakConfig, apiUrl:string, callBack:()=>void, buildNumber:string, isLocal: string) {

  const keycloak = new Keycloak({
    url: keycloakConfig.url,
    realm: keycloakConfig.realm,
    clientId: keycloakConfig.clientId,
  });

  keycloak
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then(function () {
      console.log('authenticated');
      window.keycloak = keycloak;
      getPossibleTypes(keycloak.token as string, apiUrl);
      getMfaImport(keycloak.token as string, apiUrl, callBack, buildNumber, isLocal);
    })
    .catch(function (error:Error) {
      console.log(error);
    });
}


export {
  init,
}
